<template>
    <div>
        <v-Mtitle title="SR产品"></v-Mtitle>
        <van-collapse v-model="activeNames" accordion>
            <van-collapse-item :name="item.name" v-for="(item,index) in items" :key="index">
                <div slot="title" class="title">
                    <span>{{item.title}}</span>
                </div>
                <van-image width="100%" :src="item.img" />
                <van-list>
                    <van-cell
                        v-for="(desc,index) in item.desc"
                        :key="index"
                        :title="`${desc.content}:${desc.btn}`"
                    />
                </van-list>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>
<script>
import Mtitle from '@/components/Mtitle.vue'
export default {
    data() {
        return {
            activeNames: ['1'],
            items: [
                {
                    title: '长航时监测无人机',
                    img: require('@/assets/part2_1.png'),
                    desc: [
                        {
                            content: '1.超长续航',
                            btn: '最大滞空时间长达6小时，标准监测载荷工作3小时'
                        },
                        {
                            content: '2.强抗风',
                            btn: '最大抗风7级，无惧恶劣天气'
                        },
                        {
                            content: '3.使用无忧',
                            btn: '多家保险公司全程支持，使用无忧'
                        },
                        {
                            content: '4.应用场景',
                            btn:
                                '事故现场持续监测，事故周边预警，应急通信临时空中基站'
                        }
                    ]
                },
                {
                    title: '重型救援无人机',
                    img: require('@/assets/part2_2.png'),
                    desc: [
                        {
                            content: '1.大载重',
                            btn: '最大载重70Kg，可定制150Kg级'
                        },
                        {
                            content: '2.强抗风',
                            btn: '最大抗风7级，无惧恶劣天气'
                        },
                        {
                            content: '3.使用无忧',
                            btn: '多家保险公司全程支持，使用无忧'
                        },
                        {
                            content: '4.应用场景',
                            btn:
                                '交通困难地点物资投放，救援拦阻网布设，救援绳索投放，与智能救生圈协同工作，事故现场监测'
                        }
                    ]
                },
                {
                    title: '应急智能救生圈',
                    img: require('@/assets/part2_3.png'),
                    desc: [
                        {
                            content: '1.高航速',
                            btn: '空载最高航速：海水≥7米/秒，淡水≥6米/秒'
                        },
                        {
                            content: '2.大载重',
                            btn: '最大载重120kg，可搭载普通救生圈实现多人救援'
                        },
                        {
                            content: '3.投放方便',
                            btn: '自重8.5kg，轻松投放'
                        },
                        {
                            content: '4.应用场景',
                            btn:
                                '内河、近海、湖泊、水库水下环境扫测；水域失踪人员搜寻'
                        }
                    ]
                },
                {
                    title: '水下应急搜索仪',
                    img: require('@/assets/part2_4.png'),
                    desc: [
                        {
                            content: '1.大扫宽',
                            btn: '单侧最大扫宽可达150m，满足绝大多数场合需要'
                        },
                        {
                            content: '2.可便携',
                            btn: '重量仅7kg，安装使用方便 '
                        },
                        {
                            content: '3/使用无忧',
                            btn: '多家保险公司全程支持，使用无忧'
                        },
                        {
                            content: '4.应用场景',
                            btn:
                                '内河、近海、湖泊、水库水下环境扫测；水域失踪人员搜寻'
                        }
                    ]
                },
                {
                    title: '蛙人通信定位系统',
                    img: require('@/assets/part2_5.png'),
                    desc: [
                        {
                            content: '1.定位通信系统',
                            btn:
                                '水下应急综合定位通信系统是集定位、通信于一体，保障了作业人员的安全的综合系统'
                        },
                        {
                            content: '2.设备协同工作',
                            btn:
                                '通过水面水下设备协同工作，实现了水面水下实时定位和通信'
                        },
                        {
                            content: '3.沟通交流的桥梁',
                            btn:
                                '可用于潜水、水下搜救、水下施工作业、水下执行特殊任务等，建立水面水下沟通交流的桥梁'
                        }
                    ]
                }
            ]
        }
    },
    components: {
        'v-Mtitle': Mtitle
    }
}
</script>

