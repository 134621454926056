<template>
    <div>
        <v-Mtitle title="应急救援支持系统"></v-Mtitle>
        <van-collapse v-model="activeNames" accordion>
            <van-collapse-item :name="item.name" v-for="item in items" :key="item.name">
                <div slot="title" class="title">
                    <img :src="item.img" width="20" alt />
                    <span>{{item.title}}</span>
                </div>
                {{item.desc}}
            </van-collapse-item>
        </van-collapse>
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple-dark list">
                    <div class="list_1">
                        <span>智慧应急指挥调度系统</span>
                        <span>水域救灾救援支持系统</span>
                    </div>
                    <div class="list_2">社会消防力量监管与调度系统</div>
                    <div class="list_3">
                        <span>重点单位灾害预警系统</span>
                        <span>森林火灾预警系统</span>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Mtitle from '@/components/Mtitle.vue'
export default {
    data() {
        return {
            activeNames: ['1'],
            items: [
                {
                    name: 1,
                    img: require('../assets/icon_1.png'),
                    title: '空中',
                    desc: '长航时监测无人机，重型救援无人机'
                },
                {
                    name: 2,
                    img: require('../assets/icon_2.png'),
                    title: '地面',
                    desc: '灭火机器人，室内外定位，重点单位消防预警森林防火预警'
                },
                {
                    name: 3,
                    img: require('../assets/icon_3.png'),
                    title: '通信指挥',
                    desc:
                        '电子沙盘，应急指挥终端，现场指挥沙盘一体化作战单兵，专家库支持系统，二级网络'
                },
                {
                    name: 4,
                    img: require('../assets/icon_4.png'),
                    title: '水域',
                    desc:
                        '智能动力救生圈，水面搜救艇，水下搜索仪蛙人通信定位系统，单侧部署阻拦网'
                }
            ]
        }
    },
    components: {
        'v-Mtitle': Mtitle
    }
}
</script>

<style lang="scss" scoped>
.title {
    display: flex;
    align-items: center;
    font-weight: bold;
    img {
        margin-right: 8px;
    }
}
.list {
    width: 100%;
    background: rgba(0, 138, 255, 1);
    box-shadow: 0px 10px 20px 0px rgba(0, 138, 255, 0.1);
    border-radius: 2px;
    border: 4px solid rgba(255, 255, 255, 1);
    color: #fff;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 12px;
    .list_1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
    }
    .list_2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .list_3 {
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 12px;
        &:last-child {
            font-size: 12px;
        }
    }
}
</style>