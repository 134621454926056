<template>
    <div>
        <v-Mtel></v-Mtel>
        <v-Mheader></v-Mheader>
        <v-Mbanner :bannerObj="bannerObj"></v-Mbanner>
        <v-Mpart1></v-Mpart1>
        <v-Mpart2></v-Mpart2>
        <v-Mpart3></v-Mpart3>
        <v-Mpart4></v-Mpart4>
        <v-Mbottom></v-Mbottom>
    </div>
</template>
<script>
import Mheader from '@/components/Mheader.vue'
import Mbanner from '@/components/Mbanner.vue'
import Mpart1 from '@/components/Mpart1.vue'
import Mpart2 from '@/components/Mpart2.vue'
import Mpart3 from '@/components/Mpart3.vue'
import Mpart4 from '@/components/Mpart4.vue'
import Mbottom from '@/components/Mbottom.vue'
import Mtel from '@/components/Mtel.vue'
export default {
    components: {
        'v-Mbanner': Mbanner,
        'v-Mpart1': Mpart1,
        'v-Mpart2': Mpart2,
        'v-Mpart3': Mpart3,
        'v-Mpart4': Mpart4,
        'v-Mbottom': Mbottom,
        'v-Mheader': Mheader,
        'v-Mtel': Mtel
    },
    data() {
        return {
            bannerObj: {
                title: '应急救援C4ISR',
                desc: '科技助力救援 创新服务应急',
                culture: '让消防官兵少流血少流泪',
                img: require('@/assets/banner_01.png')
            }
        }
    }
}
</script>

