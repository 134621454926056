<template>
    <div>
        <v-Mtitle title="案例展示"></v-Mtitle>
        <van-swipe :autoplay="3000" :show-indicators="false">
            <van-swipe-item v-for="(image, index) in imgs" :key="index" class="img">
                <img :src="image.img" width="100%" />
                <van-row class="desc">
                    <van-col span="24" class="title">{{image.title}}</van-col>
                </van-row>
            </van-swipe-item>
        </van-swipe>
    </div>
</template>
<script>
import Mtitle from '@/components/Mtitle.vue'
export default {
    data() {
        return {
            imgs: [
                {
                    img: require('@/assets/banner_mini_1.png'),
                    title: '2018年乌镇互联网峰会开幕式'
                },
                {
                    img: require('@/assets/banner_mini_2.png'),
                    title:
                        '2018年10月国务院委员王勇利用我司调度平台调研浙江总队及嘉兴专职队'
                },
                {
                    img: require('@/assets/banner_mini_3.png'),
                    title: '央视报道利用指挥系统和水下搜索系统组合开展救援工作'
                }
            ]
        }
    },
    components: {
        'v-Mtitle': Mtitle
    }
}
</script>

<style lang="scss" scoped>
.img {
    position: relative;
    .desc {
        position: absolute;
        width: 100%;
        color: #fff;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 100%
        );
        height: 30px;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 12px;
        text-align: center;
    }
}
</style>